import * as React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const headerPhoto = post.frontmatter.headerPhoto;

  return (
    <Layout location={location}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={headerPhoto && headerPhoto.childImageSharp.fluid.src}
      />

      <article
        className="mb-1 flex flex-col gap-16"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <span className="text-sm">{post.frontmatter.date} • {post.fields.readingTime.text}</span>
        </header>

        {
          headerPhoto &&
            <Img
            fluid={headerPhoto.childImageSharp.fluid}
            alt={post.frontmatter.title}
            title={post.frontmatter.title}
            className="mb5 mw7-ns center shadow-sm rounded-xl"
          />
        }

        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        headerPhoto {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
